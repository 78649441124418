<template>
  <div class="w-100 position-relative">
    <CCard class="">
      <div class="modal-body modal-container">
        <div class="pdf-wrapper">
          <div class="pdf-page fs-16">
            <div id="pdf2" class="pdf-content ml-3 mr-3">
              <div class="text-center fs-16">
                <strong class="bold">Mẫu C.I.3</strong><br>
                <strong>Đề án hoạt động xúc tiến đầu tư thuộc chương trình xúc tiến đầu tư quốc gia</strong><br>
                <p class="font-italic">(Áp dụng cho hồ sơ đề xuất thực hiện chương trình xúc tiến đầu tư quốc gia quy
                  định tại Điểm b, Khoản 2, Điều 92 Nghị định số 31/2021/NĐ-CP)</p>
                <hr>
              </div>
              <div class="row fs-16">
                <div class="col text-center">
                  <p class="font-weight-bold text-uppercase">{{ item.coQuanChuTri }}</p>
                  <p class="mt-5 pt-3 mr-4">Số: {{ item.soVanBan }}</p>
                </div>
                <div class="col text-center">
                  <span class="text-uppercase font-weight-bold">Cộng hòa xã hội chủ nghĩa Việt Nam</span>
                  <p class="font-weight-bold">Độc lập – Tự do – Hạnh phúc</p>
                  <hr width="180px" style="border: 1px double">
                  <p class="font-weight font-italic mt-1" style="float: right">
                    {{ item.diaDiem }}, ngày {{ getDay(item.ngayVanBan) }} tháng {{ getMonth(item.ngayVanBan) }} năm
                    {{ getYear(item.ngayVanBan) }}
                  </p>
                </div>
              </div>
              <div class="text-center">
                <b class="text-uppercase">Đề án</b> <br>
                <span class="font-italic">(Tên hoạt động: {{ its.tenHoatDong }})</span> <br>
                <span class="font-italic">(Loại hoạt động: {{
                    displayLabel2(optionsLoaiHoatDong, its.loaiHoatDongId)
                  }})</span> <br>
                <span>Kính gửi: {{ its.donViChuTriThucHien }}</span> <br>
              </div>
              <div class="col-12">
                <p class="font-italic mb-0" style="text-indent: 3em">Căn cứ Luật Đầu tư số 61/2020/QH14 ngày 17
                  tháng
                  6 năm 2020;</p>
                <p class="font-italic mb-0" style="text-indent: 3em">Căn cứ Nghị định số 31/2021/NĐ-CP ngày 26
                  tháng
                  3 năm 2021 của Chính phủ quy định chi tiết và hướng dẫn thi hành một số điều của Luật đầu
                  tư;</p>
                <p class="font-italic mb-0" style="text-indent: 3em">Căn cứ Thông tư số .../2021/TT-BKHĐT ngày
                  ...
                  tháng ... năm 2021 của Bộ Kế hoạch và Đầu tư quy định biểu mẫu thực hiện hoạt động dầu tư tại
                  Việt Nam, đầu tư từ Việt Nam ra nước ngoài và xúc tiến đầu tư;</p>
                <p class="font-italic mb-0" style="text-indent: 2em">Căn cứ hướng dẫn xây dựng chương trình xúc
                  tiến đầu tư của Bộ Kế hoạch và Đầu tư tại công văn số ... ngày ... tháng ... năm ....</p>
              </div>
              <div class="col-12">
                <p class="font-weight-bold mt-0 mb-0 pt-0 pb-0" style="text-indent: 3em">1. Sự cần thiết</p>
                <p class="mt-0 mb-0 pt-0 pb-0" style="text-indent: 3em">- Phù hợp với định hướng thu hút đầu tư, đặc
                  điểm, tiềm năng, thế mạnh của vùng, lãnh thổ; </p>
                <p class="mt-0 mb-0 pt-0 pb-0" style="text-indent: 3em">- Phù hợp với xu hướng và nhu cầu của nhóm đối
                  tác, nhà đầu tư dự kiến xúc tiến đầu tư.</p>
              </div>
              <div class="col-12">
                <p class="font-weight-bold mt-0 mb-0 pt-0 pb-0" style="text-indent: 3em">2. Nội dung hoạt động</p>
                <p class="mt-0 mb-0 pt-0 pb-0" style="text-indent: 3em">- Thời gian thực hiện:
                  {{ data.thoiGianThucHien | formatDate }}</p>
                <p class="mt-0 mb-0 pt-0 pb-0" style="text-indent: 3em">- Địa điểm (trong nước/nước ngoài):</p>
                <p class="mt-0 mb-0 pt-0 pb-0 ml-1" style="text-indent: 3em">+ Trong nước:
                  {{ displayLabel2(optionsTinhThanh, its.diaDiem_TrongNuocId) }}</p>
                <p class="mt-0 mb-0 pt-0 pb-0 ml-1" style="text-indent: 3em">+ Nước ngoài:
                  {{ displayLabel2(optionsQuocGia, its.diaDiem_NuocNgoaiId) }}</p>
                <p class="mt-0 mb-0 pt-0 pb-0" style="text-indent: 3em">- Đối tượng dự kiến tham gia:
                  {{ data.doiTuongDuKienThamGia }}</p>
                <!--                <p class="mt-0 mb-0 pt-0 pb-0" style="text-indent: 4em">{{ data.doiTuongDuKienThamGia }}</p>-->
                <p class="mt-0 mb-0 pt-0 pb-0" style="text-indent: 3em">- Quy mô hoạt động: {{ data.quyMoHoatDong }}</p>
                <!--                <p class="mt-0 mb-0 pt-0 pb-0" style="text-indent: 4em">{{ data.quyMoHoatDong }}</p>-->
                <p class="mt-0 mb-0 pt-0 pb-0" style="text-indent: 3em">- Ngành/Lĩnh vực/Địa bàn (tỉnh/vùng/quốc gia)
                  kêu gọi đầu tư;</p>
                <p class="mt-0 mb-0 pt-0 pb-0" style="text-indent: 4em">+ Ngành/Lĩnh vực kêu gọi đầu tư:
                  {{ displayLabel2(optionsNganhLinhVuc, its.nganhLinhVucKeuGoiDauTuId) }}</p>
                <p class="mt-0 mb-0 pt-0 pb-0" style="text-indent: 4em">+ Địa bàn/Tỉnh/Vùng kêu gọi đầu tư:
                  {{ displayLabel(optionsTinhThanh, its.diaBanKeuGoiDauTu) }}</p>
                <p class="mt-0 mb-0 pt-0 pb-0" style="text-indent: 3em">- Các nội dung chi tiết khác</p>
                <p class="mt-0 mb-0 pt-0 pb-0" style="text-indent: 4em">{{ data.noiDungChiTietKhac }}</p>
              </div>
              <div class="col-12">
                <p class="font-weight-bold mt-0 mb-0 pt-0 pb-0" style="text-indent: 3em">3. Phương thức triển khai</p>
                <p class="mt-0 mb-0 pt-0 pb-0" style="text-indent: 3em">- Phương thức tổ chức: {{
                    data.phuongThucToChuc
                  }}</p>
                <p class="mt-0 mb-0 pt-0 pb-0" style="text-indent: 3em">- Đơn vị phối hợp:</p>
                <p class="mt-0 mb-0 pt-0 pb-0" style="text-indent: 4em">+ Tổ chức, cơ quan trong nước:
                  {{ its.toChucCoQuan_TrongNuoc }}</p>
                <p class="mt-0 mb-0 pt-0 pb-0" style="text-indent: 4em">+ Tổ chức cơ quan nước ngoài:
                  {{ its.toChucCoQuan_NuocNgoai }}</p>
                <p class="mt-0 mb-0 pt-0 pb-0" style="text-indent: 4em">+ Doanh nghiệp trong nước, nước ngoài:
                  {{ its.doanhNghiep_TrongNuoc }}, {{ its.doanhNghiep_NuocNgoai }}</p>
              </div>
              <div class="col-12">
                <p class="font-weight-bold mt-0 mb-0 pt-0 pb-0" style="text-indent: 3em">4. Kế hoạch triển khai và tiến
                  độ thực hiện</p>
                <table class="col-12 table table-bordered table-striped">
                  <thead>
                  <tr>
                    <th class="text-center align-middle">STT</th>
                    <th class="text-center align-middle">Hạng mục công việc</th>
                    <th class="text-center align-middle">Thời gian bắt đầu</th>
                    <th class="text-center align-middle">Thời gian kết thúc</th>
                    <th class="text-center align-middle">Đơn vị thực hiện</th>
                    <th class="text-center align-middle">Đơn vị phối hợp</th>
                    <!--                              <th class="text-center align-middle">Thao tác</th>-->
                  </tr>
                  </thead>
                  <tbody>
                  <template v-if="data.hangMucCongViecCTXTDTQuocGia.length">
                    <tr class="text-center align-middle" v-for="(itz) in data.hangMucCongViecCTXTDTQuocGia"
                        v-bind:key="itz.guid">
                      <td>{{ itz.stt }}</td>
                      <td>{{ itz.tenHangMuc }}</td>
                      <td>{{ itz.thoiGianBatDau | formatDate }}</td>
                      <td>{{ itz.thoiGianKetThuc | formatDate }}</td>
                      <td>{{ itz.donViThucHien }}</td>
                      <td>{{ itz.donViPhoiHop }}</td>
                    </tr>
                  </template>
                  <template v-else>
                    <tr class="text-center align-middle">
                      <td colspan="7">Chưa có dữ liệu</td>
                    </tr>
                  </template>
                  </tbody>
                </table>
              </div>
              <div class="col-12">
                <p class="font-weight-bold mt-0 mb-0 pt-0 pb-0" style="text-indent: 3em">5. Kinh phí</p>
                <div class="mt-0 mb-0 pt-0 pb-0">
                  <div class="row">
                    <div class="col ml-5">Tổng kinh phí (1):</div>
                    <div class="col" style="margin-right: 17em">
                      {{
                        Number(its.kinhPhi_ChuongTrinhXTDTQG) + Number(its.kinhPhi_NganSachDiaPhuong) + Number(its.kinhPhi_Khac) | formatNumber
                      }} triệu đồng, trong đó:
                    </div>
                  </div>
                  <p class="ml-5 mt-0 mb-0 pt-0 pb-0">- Phần sử dụng kinh phí từ chương trình xúc tiến đầu tư quốc gia
                    (2): {{ its.kinhPhi_ChuongTrinhXTDTQG | formatNumber }} triệu đồng</p>
                  <div class="row">
                    <div class="col ml-5">- Ngân sách của Bộ, địa phương (3):</div>
                    <div class="col" style="margin-right: 12em">{{ its.kinhPhi_NganSachDiaPhuong | formatNumber }} triệu
                      đồng
                    </div>
                  </div>
                  <div class="row">
                    <div class="col ml-5">- Nguồn khác (xã hội hóa) (4):</div>
                    <div class="col" style="margin-right: 12em">{{ its.kinhPhi_Khac | formatNumber }} triệu đồng</div>
                  </div>
                </div>
              </div>
              <div class="col-12">
                <p class="mt-0 mb-0 pt-0 pb-0" style="text-indent: 3em"><span class="font-weight-bold">6. Dự toán kinh phí từ chương trình xúc tiến đầu tư quốc gia</span>
                  <span class="font-italic">(gửi kèm theo)</span></p>
                <!--                        <button class="btn btn-primary float-right" @click="addDuToanKinhPhi"><i class="cil-plus"/>Thêm-->
                <!--                          hạng mục-->
                <!--                        </button>-->
<!--                <div class="position-relative table-responsive">-->
<!--                  <table class="table table-striped table-fixed table-hover table-bordered">-->
<!--                    <thead>-->
<!--                    <tr>-->
<!--                      <th class="text-center align-middle">STT</th>-->
<!--                      <th class="text-center align-middle">Nội dung công việc</th>-->
<!--                      <th class="text-center align-middle">Đơn vị tính</th>-->
<!--                      <th class="text-center align-middle">Số lượng</th>-->
<!--                      <th class="text-center align-middle">Đơn giá</th>-->
<!--                      <th class="text-center align-middle">Thành tiền</th>-->
<!--                      &lt;!&ndash;                              <th class="text-center align-middle">Thao tác</th>&ndash;&gt;-->
<!--                    </tr>-->
<!--                    </thead>-->
<!--                    <tbody>-->
<!--                    <template v-if="data.duToanKinhPhiCTXTDTQuocGia.length">-->
<!--                      <tr class="text-center align-middle" v-for="(itz) in data.duToanKinhPhiCTXTDTQuocGia"-->
<!--                          v-bind:key="itz.guid">-->
<!--                        <td>-->
<!--                          &lt;!&ndash;                                  <span v-if="!itz.parentGuid">{{ idxz + 1 }}</span>&ndash;&gt;-->
<!--                          <span>{{ itz.stt }}</span>-->
<!--                        </td>-->
<!--                        <td>-->
<!--                          <input disabled style="background-color: white" type="text" class="form-control"-->
<!--                                 placeholder=""-->
<!--                                 v-model="itz.noiDungCongViec"/>-->
<!--                        </td>-->
<!--                        <td>-->
<!--                          <input disabled style="background-color: white" type="text" class="form-control"-->
<!--                                 placeholder=""-->
<!--                                 v-model="itz.donViTinh"/>-->
<!--                        </td>-->
<!--                        <td>-->
<!--                          <input disabled style="background-color: white" type="number" class="form-control"-->
<!--                                 placeholder=""-->
<!--                                 v-model="itz.soLuong"/>-->
<!--                        </td>-->
<!--                        <td>-->
<!--                          <input disabled style="background-color: white" type="number" class="form-control"-->
<!--                                 placeholder=""-->
<!--                                 v-model="itz.donGia"/>-->
<!--                        </td>-->
<!--                        <td>-->
<!--                                  <span-->
<!--                                    class="float-right">{{-->
<!--                                      Number(itz.soLuong) * Number(itz.donGia) | formatNumber-->
<!--                                    }}</span>-->
<!--                        </td>-->
<!--                        &lt;!&ndash;                                <td>&ndash;&gt;-->
<!--                        &lt;!&ndash;                                  <button class="btn btn-ghost-primary" v-if="!itz.parentGuid"&ndash;&gt;-->
<!--                        &lt;!&ndash;                                          @click="addDuToanKinhPhiCon(itz.guid, idxz)">Thêm hạng mục con&ndash;&gt;-->
<!--                        &lt;!&ndash;                                  </button>&ndash;&gt;-->
<!--                        &lt;!&ndash;                                  <button class="btn btn-danger" @click="deleteDuToanKinhPhi(idxz)"><i&ndash;&gt;-->
<!--                        &lt;!&ndash;                                    class="cil-trash"/>Xóa&ndash;&gt;-->
<!--                        &lt;!&ndash;                                  </button>&ndash;&gt;-->
<!--                        &lt;!&ndash;                                </td>&ndash;&gt;-->
<!--                      </tr>-->
<!--                    </template>-->
<!--                    <template v-else>-->
<!--                      <tr class="text-center align-middle">-->
<!--                        <td colspan="7">Chưa có dữ liệu</td>-->
<!--                      </tr>-->
<!--                    </template>-->
<!--                    </tbody>-->
<!--                  </table>-->
<!--                </div>-->
              </div>
              <div class="col-12">
                <p class="font-weight-bold mt-0 mb-0 pt-0 pb-0" style="text-indent: 3em">7. Đánh giá đóng góp của hoạt động xúc tiến đầu tư</p>
                <p class="font-italic mt-0 mb-0 pt-0 pb-0" style="text-indent: 3em">(Dự kiến những đóng góp của hoạt động xúc tiến đầu tư đối với phát triển kinh tế - xã hội của Bộ, ngành, địa phương, vùng)</p>
                <p class="mt-0 mb-0 pt-0 pb-0">Sau khi triển khai hoạt động xúc tiến đầu tư, <span class="font-italic">{{ item.coQuanChuTri }}</span> sẽ có báo cáo kết quả gửi <span class="font-italic">{{ its.donViChuTriThucHien }}</span> để tổng hợp gửi Bộ Kế hoạch và Đầu tư theo quy định tại Điều 103 Nghị định số 31/2021/NĐ-CP ngày 26/3/2021 quy định chi tiết và hướng dẫn thi hành một số điều của Luật Đầu tư.</p>
              </div>
              <div class="col-12 row mt-5">
                <div class="col-5 float-left">
                  <b class="font-italic">Nơi nhận:</b> <br>
                  <span class="font-italic">- .....</span> <br>
                </div>
                <div class="col-7 text-center">
                  <b>THỦ TRƯỞNG ĐƠN VỊ CHỦ TRÌ</b> <br>
                  <span class="font-italic">(Ký, ghi rõ họ tên, chức danh, đóng dấu)</span>
                  <br>
                  <br>
                  <br>
                  <br>
                  <br>
                </div>
              </div>
              <div class="col-12">
                <p class="font-italic mt-0 pt-0 pb-0">(Lưu ý: Đối với trường hợp điều chỉnh, đề nghị tạo bảng cho tất cả các nội dung và chia 2 cột thể hiện trước và sau điều chỉnh. Đối với trường hợp bổ sung theo ý kiến của Bộ Kế hoạch và Đầu tư như quy định tại Điểm c, Khoản 2, Điều 92 Nghị định 31/2021/NĐ-CP, đề nghị tạo bảng cho tất cả các nội dung bổ sung, chia 2 cột thể hiện trước và sau điều chỉnh, và để trống cột trước)</p>
              </div>
              <!--                      <div class="form-group row mb-0 mt-3">-->
              <!--                        <div class="col-12">-->
              <!--                          <b class="mt-5">Đính kèm file</b>-->
              <!--                          <div class="import-files mb-2" v-if="JSON.parse(data.dinhKem) != null">-->
              <!--                            <div class="files mb-1" v-for="file in JSON.parse(data.dinhKem)" v-bind:key="file.id">-->
              <!--                              <a :href="getDownloadURL(file.url)" target="_blank">{{ file.fileName }}</a>-->
              <!--                            </div>-->
              <!--                          </div>-->
              <!--                        </div>-->
              <!--                      </div>-->
            </div>
            <div class="print-btn close-button">
              <button class="print-button" @click="print">
                <span class="print-icon"></span></button>
            </div>
          </div>
        </div>
      </div>
    </CCard>
  </div>
</template>

<script>
import { displayLabel, displayLabel2 } from '@/shared/appConstants'
import { tinhThanhService } from '@/services/tinh-thanh.service'
import { quocGiaService } from '@/services/quoc-gia.service'
import { nganhLinhVucService } from '@/services/nganh-linh-vuc.service'
import moment from 'moment'
import appConfig from '@/shared/appConfig'
import { loaiHoatDongService } from '@/services/loai-hoat-dong.service'
import { vungKinhTeService } from '@/services/vung-kinh-te.service'
import { enums } from '@/shared/enums'

export default {
  name: 'DeXuatCTXTDTQGDeAnPrint',
  data () {
    return {
      Attachments: [],
      type: 'DeXuatCTXTDTQGDeAnPrint',
      isDeleteConfirmationModalOpen: false,
      optionsNganhLinhVuc: [],
      optionsTinhThanh: [],
      optionsQuocGia: [],
      optionsLoaiHoatDong: [],
      optionsNguoiKinhPhi: [],
      optionsDiaBanKeuGoiDauTu: [],
      item: {},
      it: {},
      its: {},
      duToanKinhPhiXTDTQuocGia: [],
      tongTienXTDTQG: 0,
      tongTienNganSach: 0,
      tongTienTaiTro: 0,
      tongTien: 0,
      data: {
        id: 0,
        deXuatCTXTDTQuocGiaGuid: '',
        chiTietDeXuatCTXTDTQuocGiaGuid: '',
        guid: '',
        thoiGianThucHien: new Date(),
        doiTuongDuKienThamGia: '',
        quyMoHoatDong: '',
        noiDungChiTietKhac: '',
        phuongThucToChuc: '',
        danhGiaDongGop: '',
        dinhKem: '',
        dinhKem_DuToanKinhPhi: '',
        hangMucCongViecCTXTDTQuocGia: [],
        duToanKinhPhiCTXTDTQuocGia: []
      }
    }
  },
  computed: {},
  methods: {
    displayLabel,
    displayLabel2,
    getDownloadURL (url) {
      return url && (url.indexOf('http://') > -1 || url.indexOf('https://') > -1) ? url : appConfig.BASE_HOST + url
    },
    getDate: (time, format = 'YYYY-MM-DD') => time ? moment(time, format).format('DD/MM/YYYY') : '',
    getDay: (time, format = 'YYYY-MM-DD') => time ? moment(time, format).format('DD') : '',
    getMonth: (time, format = 'YYYY-MM-DD') => time ? moment(time, format).format('MM') : '',
    getYear: (time, format = 'YYYY-MM-DD') => time ? moment(time, format).format('YYYY') : '',
    back () {
      this.$router.push({ path: '/de-xuat-ct-xtdt' })
    },
    getLocalStorage () {
      const jsonData = localStorage.getItem('de_xuat_ct_xtdt_quoc_gia_de_an')
      const data = JSON.parse(jsonData)
      console.log(data)
      if (data) {
        this.item = data.item
        this.its = data.its
        this.data = data.data
        this.data.hangMucCongViecCTXTDTQuocGia = data.hangMucCongViecCTXTDTQuocGia
        this.data.duToanKinhPhiCTXTDTQuocGia = data.duToanKinhPhiXTDTQuocGia
      }
    },
    print () {
      window.print()
    },
    countTongTien () {
      if (this.chiTietDeXuatCTXTDTQuocGia.length) {
        let tong = 0
        let tongCTXTDT = 0
        let tongNganSach = 0
        let tongKhac = 0
        for (const i in this.chiTietDeXuatCTXTDTQuocGia) {
          tongCTXTDT += this.chiTietDeXuatCTXTDTQuocGia[i].kinhPhi_ChuongTrinhXTDTQG
          tongNganSach += this.chiTietDeXuatCTXTDTQuocGia[i].kinhPhi_NganSachDiaPhuong
          tongKhac += this.chiTietDeXuatCTXTDTQuocGia[i].kinhPhi_Khac
          tong += this.chiTietDeXuatCTXTDTQuocGia[i].kinhPhi_ChuongTrinhXTDTQG + this.chiTietDeXuatCTXTDTQuocGia[i].kinhPhi_NganSachDiaPhuong + this.chiTietDeXuatCTXTDTQuocGia[i].kinhPhi_Khac
        }
        this.tongTienXTDTQG = tongCTXTDT
        this.tongTienNganSach = tongNganSach
        this.tongTienTaiTro = tongKhac
        this.tongTien = tong
      }
    },
    getLineThrough (trangThai) {
      if (trangThai === 4) {
        return 'text-decoration-line: line-through'
      } else {
        return ''
      }
    }
  },
  async created () {
    if (this.$route.params.id) {
      await this.getItem(this.$route.params.id)
    } else {
      await this.getLocalStorage()
    }
    if (this.item.dinhKem) this.Attachments = JSON.parse(this.item.dinhKem)
    this.optionsTinhThanh = await tinhThanhService.getAll()
    this.optionsQuocGia = await quocGiaService.getAll()
    this.optionsNganhLinhVuc = await nganhLinhVucService.getAll()
    this.optionsLoaiHoatDong = await loaiHoatDongService.getAll()
    this.optionsNguoiKinhPhi = enums.nguonKinhPhi
    this.optionsDiaBanKeuGoiDauTu = await vungKinhTeService.getAll()
  }
}
</script>

<style scoped>

</style>
